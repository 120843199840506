import clsx from 'clsx';
import React from 'react';

export type GlyphProps = {
  className?: string;
  size?:
    | 'xs'
    | 'sm'
    | 'md'
    | 'lg'
    | 'xl'
    | '2xl'
    | '3xl'
    | '4xl'
    | '5xl'
    | '6xl'
    | '7xl'
    | '8xl'
    | '9xl';
  style?: React.CSSProperties;
  x?: number;
  y?: number;
  title?: string;
  ariaHidden?: boolean;
} & ClickEventProp &
  ChildrenProp;

interface Props extends GlyphProps {
  height: number;
  width: number;
}

const Glyph: React.FC<Props> = React.memo(
  ({
    size = 'md',
    children,
    className = '',
    height,
    style,
    width,
    x = 0,
    y = 0,
    onClick,
    title,
    ariaHidden = true
  }) => {
    const classes = clsx(`text-${size}`, className);

    return (
      <svg
        width="1em"
        height="1em"
        role="img"
        viewBox={`${x} ${y} ${width} ${height}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        onClick={onClick}
        className={classes}
        aria-hidden={ariaHidden}
        aria-labelledby={title}
        style={style}>
        {title && <title id={title}>{title}</title>}
        {children}
      </svg>
    );
  }
);

Glyph.displayName = 'Glyph';

export { Glyph };
